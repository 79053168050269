import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  // OrderedList,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]
  useEffect(() => {
    setWord(selectRandomItem(data.wordsJson.spanish.nouns))
  }, [data.wordsJson.spanish.nouns])

  return (
    <BaseLayout>
      <SEO
        title="Generador de sustantivos aleatorios"
        titleSeo="Generador de sustantivos aleatorios"
        description="Herramienta para generar sustantivos al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de sustantivos aleatorios
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(selectRandomItem(data.wordsJson.spanish.nouns))
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de sustantivos aleatorios</Heading1>
        <Paragraph>
          Partiendo de una lista de miles de palabras predefinida, este
          generador de sustantivos al azar te permitir&aacute; obtener uno de
          forma aleatoria cada vez que presiones el bot&oacute;n de generar.
        </Paragraph>
        <Paragraph>
          Se trata de una herramienta online muy sencilla que cumple un objetivo
          espec&iacute;fico: tomar una lisa de muchos sustantivos del idioma
          espa&ntilde;ol y seleccionar y devolver uno de forma aleatoria.
        </Paragraph>
        <Paragraph>
          El tipo de sustantivos que aqu&iacute; se incluye son los sustantivos
          comunes, dado que, de necesitar sustantivos propios, deber&iacute;as
          dirigirte a la secci&oacute;n de generador de nombres de personas o de
          empresas, de lugares, de famosos, etc.
        </Paragraph>
        <Paragraph>
          Es decir, en este generador de sustantivos comunes aleatorios
          obtendr&aacute;s de forma aleatorio alg&uacute;n objeto o cosa, como,
          por ejemplo: un color, una fruta, una herramienta, un medio de
          transporte, un sentimiento, una comida, una parte del cuerpo, un
          deporte, una actividad, un animal, un material, etc.
        </Paragraph>
        <Heading2>
          Obtiene un sustantivo com&uacute;n al azar en un instante
        </Heading2>
        <Paragraph>
          Este generador de sustantivos al azar es tan simple como
          r&aacute;pido: con tan solo presionar el bot&oacute;n de generar,
          obtendr&aacute;s un sustantivo aleatorio al instante, pudiendo repetir
          el proceso tantas veces como quieras.
        </Paragraph>
        <Paragraph>
          No existen ajustes complicados ni m&uacute;ltiples pasos a seguir,
          simplemente podr&aacute;s generar sustantivos comunes al azar con
          utilizar un simple bot&oacute;n, lo cual tiene sentido dada la
          naturaleza tan simple de una tarea como lo es el seleccionar un
          elemento al azar desde una lista predefinida, lo cual quiz&aacute;
          resulta la parte m&aacute;s laboriosa, el crear y filtrar dicha lista
          de sustantivos desde la cual se parte para hacer la selecci&oacute;n
          aleatoria.
        </Paragraph>
        <Paragraph>
          Es una herramienta que no pretende ser m&aacute;s compleja o profunda
          de lo que realmente es, dado que cumple un objetivo muy simple
          siguiendo un funcionamiento igualmente sencillo.
        </Paragraph>
        <Heading2>
          &iquest;Qu&eacute; es un sustantivo? Definici&oacute;n y/o concepto
        </Heading2>
        <Paragraph>
          Un sustantivo en el idioma espa&ntilde;ol es un tipo de palabra o
          clase gramatical que se utiliza para referirse a una entidad, un
          objeto, una cosa, un lugar, un sujeto o un concepto. Esto va desde un
          objeto, un animal, una herramienta, un color, hasta entidades
          espec&iacute;ficas como ser una persona en concreto o un lugar
          espec&iacute;fico.
        </Paragraph>
        <Paragraph>
          Dicho objeto o entidad puede ser de diferentes subtipos, los cuales
          vamos a explorar a continuaci&oacute;n.
        </Paragraph>
        <Heading3>Tipos de sustantivos</Heading3>
        <Paragraph>
          Clasificaci&oacute;n de los sustantivos seg&uacute;n el objeto o
          entidad al que se hace referencia:
        </Paragraph>
        <Heading4>Sustantivos propios</Heading4>
        <Paragraph>
          Hacen referencia de forma espec&iacute;fica a un objeto o un sujeto,
          lo nombran. Los sustantivos propios son aquellos que utilizamos como
          nombres, ya sea para lugares, personas, mascotas, eventos, cualquier
          nombre que se refiere a una entidad espec&iacute;fica y no a un clase
          o grupo general.
        </Paragraph>
        <Paragraph>
          Algo a destacar es que siempre se escriben con may&uacute;scula
          inicial, sin importar su ubicaci&oacute;n en la oraci&oacute;n.
        </Paragraph>
        <Paragraph>
          Cualquier objeto o sujeto que pueda ser nombrado puede dar lugar a un
          sustantivo propio: una persona (Ram&oacute;n, Marta, Silvia, Favio,
          Baltasar, Carmen), un lugar (Suecia, Madrid, Am&eacute;rica, Europa,
          Plaza de la Constituci&oacute;n), un animal o una mascota (Beethoven,
          Hachiko, Laika, Pongo, Scooby Doo, Dama, Golfo), obras
          art&iacute;sticas o artistas (Harry Potter, El Se&ntilde;or de los
          Anillos, Queen, The Beatles, Aerosmith, Mona Lisa, La Noche
          Estrellada), eventos (Oktoberfest, A&ntilde;o Nuevo, Super Bowl,
          Coachella), etc.
        </Paragraph>
        <Heading4>Sustantivos comunes</Heading4>
        <Paragraph>
          Designan a una cosa o entidad de forma general, sin referirse a una
          instancia espec&iacute;fica de la misma. Cualquier objeto, animal o
          cosa a la que uno se refiere de forma general es un sustantivo
          com&uacute;n como, por ejemplo: colores (rojo, azul, morado), animales
          (castor, cig&uuml;e&ntilde;a, avestruz, picaflor), mueble (mesa,
          silla, estanter&iacute;a), deporte (baloncesto, tenis, ajedrez, golf),
          sentimiento (felicidad, angustia, tristeza, euforia), etc.
        </Paragraph>
        <Paragraph>
          Los sustantivos comunes se suelen escribir con min&uacute;scula,
          respetando las mismas reglas que el resto de palabras (si van al
          comienzo de una oraci&oacute;n, ir&aacute;n con may&uacute;sculas, por
          ejemplo). Adem&aacute;s, existen subclasificaciones entre los
          sustantivos comunes.
        </Paragraph>
        <Heading4>Clasificaci&oacute;n de los sustantivos comunes</Heading4>
        <UnorderedList>
          <ListItem>
            <strong>Abstractos: </strong>como su nombre lo indica, hacen
            referencia a cosas en el terreno conceptual o imaginario, es
            necesario utilizar nuestra capacidad de imaginaci&oacute;n y
            abstracci&oacute;n para poder entenderlos. Ejemplos: justicia,
            astucia, libertad, creatividad, felicidad, amor, motivaci&oacute;n.
          </ListItem>
          <ListItem>
            <strong>Concretos: </strong>hacen referencia a cosas f&iacute;sicas
            que pueden ser percibidas por nuestros sentidos, cosas que se pueden
            tocar, oler, ver, escuchar, degustar. Ejemplos: perro, gato,
            almanaque, puerta, l&aacute;mpara, planta, vestido.
          </ListItem>
        </UnorderedList>
        <Heading4>Clasificaci&oacute;n de los sustantivos concretos</Heading4>
        <UnorderedList>
          <ListItem>
            <strong>Contables</strong>: todos aquellos que pueden numerarse, se
            pueden contar. Ejemplo: 5 raquetas, 3 peces, 8 planetas, 7
            d&iacute;as, 12 caballos.
          </ListItem>
          <ListItem>
            <strong>No contables</strong>: aquellos cuya cantidad se puede
            medir, pero no contar, no tienen plural. Ejemplos: podemos decir
            mucha az&uacute;car, o 50 gramos de az&uacute;car, pero no podemos
            decir &ldquo;5 az&uacute;cares&rdquo;. Otro ejemplo es la harina, no
            nos referimos a 4 harinas, sino a mucha o poca harina, o a una
            cierta cantidad de gramos de harina (los gramos como tal son
            contables, pero la harina no).
          </ListItem>
        </UnorderedList>
        <Heading4>Clasificaci&oacute;n de los sustantivos contables</Heading4>
        <UnorderedList>
          <ListItem>
            <strong>Individuales: </strong>nombran a una cosa u objeto de forma
            individual. Por ejemplo: persona, &aacute;rbol, pluma,
            p&aacute;jaro, isla, oveja, perro, m&uacute;sico, lobo, pez.
          </ListItem>
          <ListItem>
            <strong>Colectivos:</strong> hacen referencia a un grupo o conjunto
            de cosas, aunque no hay que confundirlos con la forma plural. Por
            ejemplo: muchedumbre, arboleda, bosque, plumaje, bandada,
            archipi&eacute;lago, reba&ntilde;o, jaur&iacute;a, orquesta, manada,
            cardumen.
          </ListItem>
        </UnorderedList>
        <Heading2>
          Todos estos sustantivos y m&aacute;s en el generador aleatorio
        </Heading2>
        <Paragraph>
          Todos estos tipos de sustantivos diferentes que se han descripto en
          los p&aacute;rrafos anteriores los podr&aacute;s obtener al utilizar
          el generador de sustantivos aleatorios, que cuenta con una extensa
          lista de sustantivos comunes que podr&aacute;s generar al azar.
        </Paragraph>
        <Paragraph>
          En caso de necesitar un generador de sustantivos propios aleatorios
          entonces lo recomendado ser&iacute;a que te dirijas a los generadores
          de nombres de personas, de lugares, de mascotas, etc. que
          podr&aacute;s encontrar en este mismo sitio web.
        </Paragraph>
        <Paragraph>
          El generador de sustantivos aleatorios resulta muy &uacute;til y
          pr&aacute;ctico justamente por su versatilidad y facilidad de uso, uno
          no tiene que preocuparse por las categor&iacute;as y clasificaciones
          al utilizarlo, ya que ser&aacute;n sustantivos comunes generados de
          forma aleatoria, bebiendo de una lista que contiene sustantivos
          concretos, abstractos, contables, no contables, individuales y
          colectivos.
        </Paragraph>
        <Paragraph>
          La mayor&iacute;a de actividades que requieren de la generaci&oacute;n
          de sustantivos de forma aleatorio no dependen de generar una palabra
          con caracter&iacute;sticas espec&iacute;ficas, sino que pueden
          funcionar correctamente con cualquier sustantivo com&uacute;n generado
          al azar, para lo cual esta herramienta online resulta id&oacute;nea
          por su facilidad de uso y la rapidez con la que se pueden generar
          decenas de sustantivos distintos si as&iacute; lo deseamos.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
